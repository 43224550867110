import { Button,Col,Checkbox,DatePicker,Form,Input,Row,Select,theme,
  Radio,Divider,Upload,Modal,message,Alert} from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Heritage } from '../../../../models/Heritage'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import heritageService from '../../../../services/heritage.service'
import LocationSelector from '../../../../components/LocationSelector/locationSelector.component'
import { draftService } from '../../../../services/draft.service'
import { getBase64, getSubtypes } from '../../../../utils/helpers'
import DropdownCountries from '../../../../components/DropdownCountries/DropdownCountries.component'
import DropdownStates from '../../../../components/DropdownStates/DropdownStates.component'
import DropdownCities from '../../../../components/DropdownCities/DropdownCities.component'
import { protected_values } from '../../../../databases/protected_values.js'
import { userMessages } from '../../../../utils/userMessages.js'
import DropdownUsers from '../../../../components/DropdownUsers/DropdownUsers.component'
import { InfoTooltip } from '../../../../components/InfoTooltip/InfoTooltip.js'

const { useToken } = theme

const HeritageForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()
  const { token } = useToken()

  const [draft, setDraft] = useState()
  const [heritage, setHeritage] = useState(element ? element : new Heritage())
  const [selectedSubtype, setSelectedSubtype] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [heritageFieldvalues, setHeritageFieldValues] = useState({ heritage_field_id: '1-1' })

  const bibliographyLink = {
    name: React.useRef(),
    url: React.useRef()
  }
  const interestLink = {
    name: React.useRef(),
    url: React.useRef()
  }

  const [bibliographyLinksList, setBibliographyLinksList] = useState([])
  const [interestLinksList, setInterestLinksList] = useState([])
  const [heritageSubtypes, setHeritageSubtypes] = useState([])
  const [heritageTags, setHeritageTags] = useState([])
  const [heritageFields, setHeritageFields] = useState([])
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const validate = (values) => {
    const errors = {}
    //if (!values?.date) errors.date = 'The date is required'
    if (!values?.organization) errors.organization = 'The organization is required'
    if (!values?.email) errors.email = 'The email is required'
    if (!values?.country_id) errors.country_id = 'The country is required'
    if (!values?.state_id) errors.state_id = 'The state is required'
    if (!values?.city_id) errors.city_id = 'The city is required'
    if (!values?.name) errors.name = 'The name is required'
    if (!values?.short_heritage_description)
      errors.short_heritage_description = 'The heritage short description is required'
    if (!values?.extended_heritage_description)
      errors.extended_heritage_description = 'The heritage extended description is required'
    if (!values?.short_heritage_description_local)
      errors.short_heritage_description_local = 'The local heritage short description is required'
    if (!values?.extended_heritage_description_local)
      errors.extended_heritage_description_local = 'The local heritage extended description is required'
    if (!values?.heritage_field_id) errors.heritage_field_id = 'The heritage field is required'
    //if (!values?.periodicity) errors.periodicity = 'The periodicity is required'
    if (!values?.ownership) errors.ownership = 'The ownership is required'
    if (!values?.latitude) errors.latitude = 'The latitude is required'
    if (!values?.longitude) errors.longitude = 'The longitude is required'
    if ([1, 2, 3].includes(values?.heritage_field_id) && !values?.subtype) errors.subtype = 'The subtype is required'

    for (const error in errors) {
      message.error(errors[error])
      break
    }
    return errors
  }

  // Handle Bibliography Links
  const addBibliographyLink = () => {
    const obj = {
      name: bibliographyLink.name.current.value,
      url: bibliographyLink.url.current.value
    }
    setBibliographyLinksList((old) => [...old, obj])
    resetBibliographyLinkForm()
  }

  const resetBibliographyLinkForm = () => {
    bibliographyLink.name.current.value = ''
    bibliographyLink.url.current.value = ''
  }

  const removeBibliographyLinkElement = (index) => {
    let array = [...bibliographyLinksList]
    array.splice(index, 1)
    setBibliographyLinksList(array)
  }

  // Handle Interest Links
  const addInterestLink = () => {
    const obj = {
      name: interestLink.name.current.value,
      url: interestLink.url.current.value
    }
    setInterestLinksList((old) => [...old, obj])
    resetInterestLinkForm()
  }

  const resetInterestLinkForm = () => {
    interestLink.name.current.value = ''
    interestLink.url.current.value = ''
  }

  const removeInterestLinkElement = (index) => {
    let array = [...interestLinksList]
    array.splice(index, 1)
    setInterestLinksList(array)
  }

  const toggleProtectedValues = (checked, name, values) => {
    return { ...values, [name]: checked }
  }

  // Handle image Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])
  const [existThisCity, setExistThisCity] = useState([])
  
  const setFieldsData = (field_id = 1, type_id = 1, subtype_id, tags=[], heritageFieldsList = heritageFields,IsSubTypeSelected = false) => {
    setHeritageFieldValues({ heritage_field_id: `${field_id}-${type_id}` })
    const Subtypes = getSubtypes(field_id, type_id, heritageFieldsList)
    const filteredSubtypes = Subtypes.map((element) => ({ value: element.id, label: element.name }))
    setHeritageSubtypes(filteredSubtypes)
    let subTypeId = IsSubTypeSelected ? filteredSubtypes[0].value : subtype_id
    console.log("subTypeId",subTypeId)
    if (subTypeId) {
      const selectedSubtype = filteredSubtypes.find((element) => element.value == subTypeId)
      const selectedSubtypeTags = Subtypes.find((element) => element.id == subTypeId)?.tags
      const filteredTagsList = selectedSubtypeTags?.map((element) => ({ value: element.id, label: element.name }))
      setHeritageTags(filteredTagsList)
      setSelectedTags(tags)
      setSelectedSubtype(selectedSubtype)
      form.setFieldsValue({ subtype: selectedSubtype })
      form.setFieldsValue({ tags: tags })
    }
  }
  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file, open = true) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(open)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChangeUpload = ({ fileList: newFileList }) => {
    setPreviewImage('')
    setFileList(newFileList)
  }
  const handleBeforeUploadImage = (file) => {
    setFileList([...fileList, file])
    return false
  }

  const saveDraft = async (values) => {
    draftService.saveDraft('heritage', { ...values, bibliography: bibliographyLinksList, links: interestLinksList })
    message.success(userMessages.draftSaved)
  }

  const removeDraft = async () => {
    await draftService.removeDraft('heritage')
    message.success(userMessages.draftRemoved)
    setDraft(null)
  }

  const restoreDraft = async () => {
    try {
      let updatedFieldsData = null

      if (draft.value?.tags?.[0]) {
        const response = await heritageService.getTagSubTypeFieldByID(draft.value.tags[0])
        updatedFieldsData = {
          fieldId: response?.field_id,
          typeId: response?.type_id,
          subtypeId: response?.subtype_id
        }
      }

      setHeritage(draft.value)
      setBibliographyLinksList(draft.value?.bibliography ?? [])
      setInterestLinksList(draft.value?.links ?? [])

      if (updatedFieldsData) {
        setFieldsData(
          updatedFieldsData.fieldId,
          updatedFieldsData.typeId,
          updatedFieldsData.subtypeId,
          draft.value?.tags,
          heritageFields
        )
      }

      message.success(userMessages.draftRestored)
    } catch (error) {
      console.error('Error restoring draft:', error)
      message.error('Failed to restore the draft.')
    } finally {
      // Now remove the draft by its ID after it's restored
      if (draft?.id) {
        await removeDraft(draft.id) // Remove the draft by its ID
      }
      setDraft(null) // Clean up the draft regardless of success or failure
    }
  }

  useEffect(() => {
    heritageService.getAllHeritageFieldsWithTypeSubTypeTags().then((fieldsArr) => {
      setHeritageFields(fieldsArr)
      // eslint-disable-next-line no-sparse-arrays
      setFieldsData(...[, , , , fieldsArr,true])
    })
  }, [])

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      setBibliographyLinksList([])
      setInterestLinksList([])
      setFileList([])
      setPreviewImage('')
      draftService
        .getDraft('heritage')
        .then((draft) => setDraft(draft))
        .catch((error) => console.log('Error', error))
      return
    }

    heritageService.getHeritageById(element.id).then((heritage) => {
      if (heritage?.tags[0]) {
        heritageService.getTagSubTypeFieldByID(heritage?.tags[0]).then((response) => {
          setFieldsData(response?.field_id, response.type_id, response.subtype_id, heritage?.tags, heritageFields)
        })
      }
      setHeritage(heritage)
      setBibliographyLinksList(heritage.bibliography ?? [])
      setInterestLinksList(heritage.links ?? [])
    })
  }, [element, props, heritageFields])

  useEffect(() => {
    if (heritage.image && heritage.image !== '') {
      handlePreview({ url: heritage.image }, false)
    }
  }, [heritage])

  const imageUploadBtn = (
    <div> <PlusOutlined /> <div style={{ marginTop: 8 }} > Upload </div> </div>
  )
  const [form] = Form.useForm()
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...heritage }}
      validate={validate}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        const form = { ...values }
        form.image = fileList[0]?.originFileObj
        form.bibliography = bibliographyLinksList
        form.links = interestLinksList
        form.tags = JSON.stringify(values?.tags)
        form.date = dayjs(values.date).format('YYYY-MM-DD')
        try {
          if (element) {
            await heritageService.updateHeritageById(heritage.id, form)
            onUpdate?.()
            message.success(userMessages.updated)
          } else {
            await heritageService.createHeritage(form)
            onAdd?.()
            message.success(userMessages.created)
          }
          actions.resetForm()
          setFileList([])
          setPreviewImage('')
          setBibliographyLinksList([])
          setInterestLinksList([])
        } catch (error) {
          console.error(error)
          message.error(`${userMessages.error} ${error.message}`)
        }
        actions.setSubmitting(false)
      }}
    >
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
        <Form
          style={{
            maxWidth: 900
          }}
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={'form-divider'}>
            Heritage Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Name of the Heritage')}
                name="name"
                className={{ 'input-error': errors.name && touched.name }}
              >
                <Input name="name" id="name" onChange={handleChange} value={values.name} />
                <ErrorMessage name="name" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Date')}
                name="date"
                className={{ 'input-error': errors.date && touched.date }}
              >
                <DatePicker
                  name="date"
                  className={{ 'border-danger': errors.date && touched.date }}
                  id="date"
                  style={{ width: '100%' }}
                  value={dayjs(values.date)}
                  onChange={(date, dateString) => {
                    setFieldValue('date', dateString)
                  }}
                />
                <ErrorMessage name="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <DropdownCountries
                error={errors.country_id}
                value={values.country_id}
                onChange={(value) => {
                  setFieldValue('country_id', value)
                  setFieldValue('state_id', null)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DropdownStates
                error={errors.state_id}
                country_id={values.country_id}
                value={values.state_id}
                onChange={(value) => {
                  setFieldValue('state_id', value)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <DropdownCities error={errors.city_id} state_id={values.state_id} value={values.city_id} originEntity='heritage'
              onChange={(value, existThisCity) => { setFieldValue('city_id', value), setExistThisCity(existThisCity)}}
              />
            </Col>

            <Col xs={24} md={12}>
              <Form.Item required label={t('Organization or Personal Information')} name="organization" className={{ 'input-error': errors.organization && touched.organization }}>
                <>
                  <Input name="organization" id="organization" onChange={handleChange} value={values.organization} />
                  <ErrorMessage name="organization" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <DropdownUsers
                error={errors.ownership}
                value={values.ownership ? Number(values.ownership) : ''}
                onChange={(value) => {
                  setFieldValue('ownership', value)
                }}
              />
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t('Periodicity')}
                name="periodicity"
                className={{ 'input-error': errors.periodicity && touched.periodicity }}
              >
                <>
                  <Input name="periodicity" id="periodicity" onChange={handleChange} value={values.periodicity} />
                  <ErrorMessage name="periodicity" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                required
                label={t('Email')}
                name="email"
                className={{ 'input-error': errors.email && touched.email }}
              >
                <>
                  <Input name="email" id="email" onChange={handleChange} value={values.email} />
                  <ErrorMessage name="email" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label={t('Image Author Copyright')}
                name="image_copyright"
                className={{ 'input-error': errors.image_copyright && touched.image_copyright }}
              >
                <>
                  <Input
                    name="image_copyright"
                    id="image_copyright"
                    onChange={handleChange}
                    value={values.image_copyright}
                  />
                  <ErrorMessage name="image_copyright" />
                </>
              </Form.Item>
            </Col>

            <Col md={12} sm={24}>
              <Form.Item label={t('Image')}>
                <>
                  <Upload
                    className="w-auto"
                    accept="image/png, image/jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeUpload}
                    beforeUpload={handleBeforeUploadImage}
                  >
                    {fileList.length == 1 ? null : imageUploadBtn}
                  </Upload>
                  {heritage.image !== '' && previewImage !== '' && fileList.length == 0 && (
                    <div className="image-preview">
                      <img alt="example" src={previewImage} />
                    </div>
                  )}
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                      alt="example"
                      style={{
                        width: '100%'
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </>
                <Alert message={t('Warnin Image Resolution')} type="warning" showIcon />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                required
                label={
                  <>
                    {t('Heritage description (Short)')}
                    <InfoTooltip text={t('Tooltip short description')} />
                  </>
                }
                name="short_heritage_description"
                className={{
                  'input-error': errors.short_heritage_description && touched.short_heritage_description
                }}
              >
                <TextArea
                  name="short_heritage_description"
                  id="short_heritage_description"
                  className={{
                    'border-danger': errors.short_heritage_description && touched.short_heritage_description
                  }}
                  showCount
                  maxLength={250}
                  style={{ height: '100%', resize: 'none' }}
                  onChange={handleChange}
                  value={values.short_heritage_description}
                />
                <ErrorMessage name="short_heritage_description" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label={
                  <>
                    {t('Heritage description (Extended)')}
                    <InfoTooltip text={t('Tooltip extended description')} />
                  </>
                }
                name="extended_heritage_description"
                className={{
                  'input-error': errors.extended_heritage_description && touched.extended_heritage_description
                }}
              >
                <TextArea
                  name="extended_heritage_description"
                  id="extended_heritage_description"
                  className={{
                    'border-danger': errors.extended_heritage_description && touched.extended_heritage_description
                  }}
                  showCount
                  maxLength={3500}
                  style={{ height: '100%', resize: 'none' }}
                  onChange={handleChange}
                  value={values.extended_heritage_description}
                />
                <ErrorMessage name="extended_heritage_description" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label={
                  <>
                    {t('Heritage description (short local language)')}
                    <InfoTooltip text={t('Tooltip short description')} />
                  </>
                }
                name="short_heritage_description_local"
                className={{
                  'input-error': errors.short_heritage_description_local && touched.short_heritage_description_local
                }}
              >
                {/* TextArea para ingresar el valor */}
                <TextArea
                  name="short_heritage_description_local"
                  id="short_heritage_description_local"
                  className={{
                    'border-danger': errors.short_heritage_description_local && touched.short_heritage_description_local
                  }}
                  showCount
                  maxLength={250}
                  style={{ height: '100%', resize: 'none' }}
                  onChange={handleChange}
                  value={values.short_heritage_description_local}
                />

                {/* Mensaje de error */}
                <ErrorMessage name="short_heritage_description_local" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label={
                  <>
                    {t('Heritage description (extended local language)')}
                    <InfoTooltip text={t('Tooltip extended description')} />
                  </>
                }
                name="extended_heritage_description_local"
                className={{
                  'input-error':
                    errors.extended_heritage_description_local && touched.extended_heritage_description_local
                }}
              >
                <TextArea
                  name="extended_heritage_description_local"
                  id="extended_heritage_description_local"
                  className={{
                    'border-danger':
                      errors.extended_heritage_description_local && touched.extended_heritage_description_local
                  }}
                  showCount
                  maxLength={3500}
                  style={{ height: '100%', resize: 'none' }}
                  onChange={handleChange}
                  value={values.extended_heritage_description_local}
                />
                <ErrorMessage name="extended_heritage_description_local" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Heritage Fields
              </Divider>
            </Col>

            <Col span={24}>
              <Form.Item className={{ 'input-error': errors.heritage_field_id && touched.heritage_field_id }}>
                <Radio.Group
                  className="radio-group-container"
                  name="heritage_field_id"
                  id="heritage_field_id"
                  onChange={(e) => {
                    const [fieldId, typeId] = e.target.value.split('-')
                    // FieldId -> se refiere a heritages Field   ||  typeId se refiere a heritageFieldType
                    setFieldValue('heritage_field_id', typeId)
                    setHeritageFieldValues({ heritage_field_id: e.target.value }) // Update state
                    const Subtypes = getSubtypes(fieldId, typeId, heritageFields)
                    const filteredSubtypes = Subtypes.map((element) => ({ value: element.id, label: element.name }))
                    setHeritageSubtypes(filteredSubtypes)
                    setHeritageTags([])
                    form.setFieldsValue({ tags: [] })
                    form.setFieldsValue({ subtype: null })
                    setSelectedTags([])
                    setSelectedSubtype(null)
                  }}
                  value={heritageFieldvalues.heritage_field_id}
                >
                  {
                  // ES AQUI DONDE ESTA EL PROBLEMA EN FIELD.id y TTYPE.id o eso deberia
                  // El problema consiste en que al poner el valor del radio esta confundiendo el id del type con el fieldtype
                  heritageFields.map((field) => 
                   { 
                    return(
                    <div className="radio-container" key={field.id}>
                      <h5>{field.name}</h5>
                      {field.types.map((type) => (
                        <div key={type.id}>
                          <Radio value={`${field.id}-${type.id}`}>{type.name}</Radio>
                        </div>
                      ))}
                    </div>
                  )})}
                </Radio.Group>

                <ErrorMessage name="heritage_field_id" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                required
                label={t('Subtype')}
                name="subtype"
                className={{ 'input-error': errors.subtype && touched.subtype }}
              >
                <Select
                  name="subtype"
                  id="subtype"
                  style={{
                    width: '100%'
                  }}
                  placeholder="Select a subtype..."
                  options={heritageSubtypes}
                  value={selectedSubtype}
                  onChange={(selectedValue) => {
                    form.setFieldsValue({ tags: [] })
                    setSelectedTags([])
                    const selectedSubtype = heritageSubtypes.find((element) => element.value == selectedValue)
                    setSelectedSubtype(selectedSubtype)
                    setFieldValue('subtype', selectedValue)
                    const [fieldId, typeId] = heritageFieldvalues.heritage_field_id.split('-')
                    let filteredSubtypes = getSubtypes(fieldId, typeId, heritageFields)

                    const tagsList = filteredSubtypes.find((element) => element.id == selectedValue)?.tags

                    const filteredTagsList = tagsList.map((element) => ({ value: element.id, label: element.name }))

                    setHeritageTags(filteredTagsList)
                  }}
                />
                <ErrorMessage name="subtype" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                required
                label={t('Tags')}
                name="tags"
                className={{ 'input-error': errors.tags && touched.tags }}
              >
                {heritageTags?.length > 0 && (
                  <Select
                    name="tags"
                    id="tags"
                    mode="multiple"
                    style={{
                      width: '100%'
                    }}
                    placeholder="Select Tags"
                    options={heritageTags}
                    value={
                      selectedTags.length > 0
                        ? selectedTags.map((tag) => heritageTags.find((t) => t.value == tag || t.label == tag)?.value)
                        : []
                    }
                    onChange={(value) => {
                      setSelectedTags(value)
                      setFieldValue('tags', value)
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Heritage Validations
              </Divider>
            </Col>

            <Col xs={12} sm={8}>
              <Form.Item
                label={t('Is Museum?')}
                name="is_museum"
                className={{ 'input-error': errors.is_museum && touched.is_museum }}
              >
                <Radio.Group name="is_museum" id="is_museum" onChange={handleChange} value={values.is_museum}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
                <ErrorMessage name="is_museum" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                label={t('Is Protected?')}
                name="is_protected"
                className={{ 'input-error': errors.is_protected && touched.is_protected }}
              >
                <>
                  <Radio.Group
                    name="is_protected"
                    id="is_protected"
                    onChange={handleChange}
                    value={values.is_protected}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <ErrorMessage name="is_protected" />
                </>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                label={t('Is Verified?')}
                name="is_verified"
                className={{ 'input-error': errors.is_verified && touched.is_verified }}
              >
                <Radio.Group name="is_verified" id="is_verified" onChange={handleChange} value={values.is_verified}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
                <ErrorMessage name="is_verified" />
              </Form.Item>
            </Col>
            <Col span={24}>
              {values.is_protected ? (
                <>
                  <Col span={24}>
                    <Divider orientation="left" className={'form-divider'}>
                      Is protected by:
                    </Divider>
                  </Col>
                  <Checkbox
                    name="protected_values"
                    className="ps-3 mt-3"
                    id="1"
                    onChange={(e) => {
                      setFieldValue(
                        'protected_values',
                        toggleProtectedValues(e.target.checked, 1, values.protected_values)
                      )
                    }}
                    checked={Boolean(values.protected_values?.[1])}
                  >
                    UNESCO
                  </Checkbox>

                  <Checkbox
                    style={{ padding: '10px 0px' }}
                    key="17"
                    name="protected_values"
                    id={17}
                    onChange={(e) => {
                      setFieldValue(
                        'protected_values',
                        toggleProtectedValues(e.target.checked, 17, values.protected_values)
                      )
                    }}
                    checked={Boolean(values.protected_values?.[17])}
                  >
                    {'Others'}
                  </Checkbox>
                  {values.protected_values?.[17] ? (
                    <Input
                      name="protected_values"
                      id="18"
                      onChange={(e) =>
                        setFieldValue('protected_values', {
                          ...values.protected_values,
                          18: e.target.value
                        })
                      }
                      value={values.protected_values?.[18]}
                    />
                  ) : null}
                  {values.protected_values[1] ? (
                    <div className="ps-3 mt-2" style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className="fw-bold">Cultural criteria</p>

                      {protected_values.map((item, index) => (
                        <>
                          {index === 6 ? <p className="fw-bold">Natural criteria</p> : null}
                          {index === 10 ? <p className="fw-bold">Intangible criteria</p> : null}
                          <Checkbox
                            style={{ padding: '10px 0px' }}
                            key={item.id}
                            name="protected_values"
                            id={item.id}
                            onChange={(e) => {
                              setFieldValue(
                                'protected_values',
                                toggleProtectedValues(e.target.checked, item.id, values.protected_values)
                              )
                            }}
                            checked={Boolean(values.protected_values?.[item.id])}
                          >
                            {item.label}
                          </Checkbox>
                        </>
                      ))}
                    </div>
                  ) : null}
                </>
              ) : null}
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Related Documentation
              </Divider>
            </Col>

            <Col xs={8} md={5}>
              <Checkbox name="has_photos" id="has_photos" onChange={handleChange} checked={values.has_photos}>
                Photos
              </Checkbox>
            </Col>
            <Col xs={8} md={5}>
              <Checkbox name="has_audios" id="has_audios" onChange={handleChange} checked={values.has_audios}>
                Audios
              </Checkbox>
            </Col>
            <Col xs={8} md={5}>
              <Checkbox name="has_videos" id="has_videos" onChange={handleChange} checked={values.has_videos}>
                Videos
              </Checkbox>
            </Col>
            <Col xs={8} md={4}>
              <Checkbox name="has_maps" id="has_maps" onChange={handleChange} checked={values.has_maps}>
                Maps
              </Checkbox>
            </Col>
            <Col xs={8} md={5}>
              <Checkbox
                name="has_bibliography"
                id="has_bibliography"
                onChange={handleChange}
                checked={values.has_bibliography}
              >
                Bibliography
              </Checkbox>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Location
              </Divider>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Latitude')}
                name="latitude"
                className={{ 'input-error': errors.latitude && touched.latitude }}
              >
                <Input name="latitude" id="latitude" onChange={(e) => {
                  const justNumbers = e.target.value.replace(/[^0-9.,]/g, '');
                    const value = justNumbers.replace(',', '.');
                    handleChange({ target: { name: 'latitude', value } });
                    }}
                    value={values.latitude} />
                <ErrorMessage name="latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Longitude')}
                name="longitude"
                className={{ 'input-error': errors.longitude && touched.longitude }}
              >
                <Input name="longitude" id="longitude" onChange={(e) => {
                  const justNumbers = e.target.value.replace(/[^0-9.,]/g, '');
                    const value = justNumbers.replace(',', '.');
                    handleChange({ target: { name: 'longitude', value } });
                    }}value={values.longitude} />
                <ErrorMessage name="longitude" />
              </Form.Item>
            </Col>

            <LocationSelector
              height="300px"
              onLocationSelected={({ lat, lng }) => {
                setFieldValue('latitude', lat)
                setFieldValue('longitude', lng)
              }}
              lat={values.latitude}
              lng={values.longitude}
            />

            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Bibliography
              </Divider>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('Name')}>
                <input
                  type="text"
                  className="ant-input css-dev-only-do-not-override-ph9edi"
                  ref={bibliographyLink.name}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('URL')}>
                <input
                  type="text"
                  className="ant-input css-dev-only-do-not-override-ph9edi"
                  ref={bibliographyLink.url}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addBibliographyLink()}>
                  {/*<PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />*/}
                  Save
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                {bibliographyLinksList.map((link, index) => {
                  return (
                    <div className="link-element" key={link.id}>
                      <div className="w-100">
                        <small>Name</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.name}
                          onChange={(event) => {
                            link.name = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.url}
                          onChange={(event) => {
                            link.url = event.target.value
                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeBibliographyLinkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Links of Interest
              </Divider>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('Name')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={interestLink.name} />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('URL')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={interestLink.url} />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addInterestLink()}>
                  {/*<PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />*/}
                  Save
                  </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                {interestLinksList.map((link, index) => {
                  return (
                    <div className="link-element" key={link.id}>
                      <div className="w-100">
                        <small>Name</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.name}
                          onChange={(event) => {
                            link.name = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.url}
                          onChange={(event) => {
                            link.url = event.target.value
                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeInterestLinkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button style={{ width: '50%' }} type="primary" htmlType="button" onClick={() => saveDraft(values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} disabled={!existThisCity} type="primary"  htmlType="submit" className="mx-auto">
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default HeritageForm
